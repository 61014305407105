import React from "react";
import { Container } from "reactstrap";
import {
  Button
} from "reactstrap";

const JoinInHeader = () => {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  const handleGetApplicationSubmit = (event) => {
    //console.log("handleGetApplicationSubmit");
    if (window.gtag) {
      window.gtag('event', 'click', {
        event_category: 'outbound',
        event_label: 'ShopApplicationDownload',
        transport_type: 'beacon',
      });
    } 
  }

  return (
    <>
      <div
        className="page-header page-header-fit"
        ref={pageHeader}
        style={{
          backgroundImage:
            "url(" + require("../../../images/bgs/citylove_map_grey.jpg") + ")"
        }}
      >
        <div className="content-center">
          <Container>
            <div
              className="ml-auto mr-auto 0mb-5"
              style={{
                width: 150,
                height: 150,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundImage:
                  "url(" +
                  require("../../../images/logos/citylove_logo_full.svg") +
                  ")"
              }}
            ></div>
            <h2 className="title">citylove Partner werden</h2>
            <h5 className="">
                {/* Erstelle für dein Unternehmen ganz einfach sichere, digitale Gutscheine und mache sie einem breiten Publikum zugänglich.<br/> */}
                Auf <span className="text-uppercase">Anmelden</span> klicken, Formular ausfüllen und per Email an hello@citylove schicken.
            </h5>
            <div
              className="mt-5 mb-3">
                <form method="get" action="/downloads/citylove_anmeldung_unternehmer.pdf" onSubmit={handleGetApplicationSubmit} target="_blank" >
                <Button className="btn btn-primary btn-round btn-lg">
                    Anmelden
                </Button>
                </form>
            </div>

            <p className="">
                Logo, Bild und kurze Infos zum Unternehmen bereithalten.
            </p>
          </Container>
        </div>
      </div>
    </>
  );
};

export default JoinInHeader;
