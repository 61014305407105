import React from "react";
import Layout from "../components/layout";
import {
  Container
} from "reactstrap";

import withUIKit from "../withUIKit";
import JoinInHeader from "../components/Custom/Headers/JoininHeader";
//import { localize } from "src/components/language/config";

import Navbar from "../components/Custom/Navbar";
import { LocalizedButton } from "../components/language/LocalizedButton";


const GetPartner = props => {
  return (
    <Layout {...props} >
      <Navbar {...props} />
      <JoinInHeader />
      <div className="wrapper">

        <div className="section landing-section text-center">
            <Container>
                <h2 className="mb-3">Du willst citylove unterstützen?</h2>
                <p>Melde dich bei uns:</p>
                <h6 className="mt-3">
                    Sponsoranfragen
                </h6> 
                <p><a href="mailto:hello@citylove.community">hello@citylove.community</a></p>
                <h6 className="mt-3">
                    Medienkontakt
                </h6>
                <p>Birgit Gruber<br/>
                <a href="mailto:birgit@gruber.tirol">birgit@gruber.tirol</a><br/>
                +43-676-5300200
                </p>
            </Container>
        </div>

        <hr />

        <div className="section landing-section text-center">
          <Container className="ml-auto mr-auto ">
            {/* <LocalizedButton
              className="btn-primary btn-round ml-2 mr-2 mt-2 mb-2"
              color="danger"
              language={props.language}
              to="/"
            >
              Gutscheine finden
            </LocalizedButton> */}
            {/* <LocalizedButton
              className="btn-primary btn-round ml-2 mr-2 mt-2 mb-2"
              color="danger"
              language={props.language}
              to="/how-to"
            >
              So funktioniert's
            </LocalizedButton> */}
            <LocalizedButton
              className="btn-primary btn-round ml-2 mr-2 mt-2 mb-2"
              color="danger"
              language={props.language}
              to="/disclaimer"
            >
              AGB
            </LocalizedButton>
            <LocalizedButton
              className="btn-primary btn-round ml-2 mr-2 mt-2 mb-2"
              color="danger"
              language={props.language}
              to="/about"
            >
              Über
            </LocalizedButton>
          </Container>
        </div>
      </div>
    </Layout>
  );
};

export default withUIKit(GetPartner);
